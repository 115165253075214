import {
  ArrowPathIcon,
  UserIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useTransaction } from "../../store";

import LoaderImg from "../../assets/img/loader.gif";

const Home = () => {
  const [profile, setProfile] = useState<any>();
  const { getProfile } = useAuth();
  const navigate = useNavigate();
  const {
    getPendingTransactions,
    createTransaction,
    approveTransaction,
    rejectTransaction,
  } = useTransaction();
  const [operations, setOperations] = useState<any[]>([]);

  const [displayAmountModal, setDisplayAmountModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [displayPasswordModal, setDisplayPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [displayDetails, setDisplayDetails] = useState(false);
  const [loader, setLoader] = useState(false);
  const [displayResModal, setDisplayResModal] = useState(false);
  const [response, setResponse] = useState<any>();

  useEffect(() => {
    const localProfile = getProfile();
    getLastOperation();
    // loadBankAccounts();
    // console.log(profile);
    localProfile ? setProfile(localProfile) : navigate("/");
  }, []);

  const getLastOperation = async () => {
    const data = await getPendingTransactions();
    setOperations(data);
  };

  const onValidateAmount = async () => {
    // await displayLoader();
    await setDisplayAmountModal(false);
    await setDisplayPasswordModal(true);
  };

  const onMakeOperation = async () => {
    setLoader(true);
    const res = await createTransaction(amount, password);
    setDisplayPasswordModal(false);
    setLoader(false);
    console.log(res);
    setResponse({
      success: res.res === "Success",
      message: res.message || "Transaction en cours...",
    });
    setDisplayResModal(true);
    getLastOperation();
  };

  const hasVoted = () => {
    const val = !!operations[0].approvals.find(
      (approval: any) =>
        approval.approver_name.toUpperCase() === profile.name.toUpperCase()
    );
    console.log("has voted", val, profile.name);
    return val;
  };

  const approveTrans = async () => {
    setLoader(true);
    const res = await approveTransaction(operations[0].id);
    setLoader(false);
    setResponse({
      success: res.res === "Success",
      message: res.message || "Transaction validée",
    });
    setDisplayResModal(true);
    getLastOperation();
    console.log(res);
  };

  const rejectTrans = async () => {
    setLoader(true);
    const res = await rejectTransaction(operations[0].id);
    setLoader(false);
    setResponse({
      success: res.res === "Success",
      message: res.message || "Transaction rejetée",
    });
    setDisplayResModal(true);
    getLastOperation();
    console.log(res);
  };

  return (
    <>
      {/* Amount */}
      {displayAmountModal ? (
        <div
          className=" absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end md:justify-center"
          onClick={($event) => {
            setDisplayAmountModal(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto max-w-md p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2>

            <input
              onChange={($event) => setAmount(+$event.target.value)}
              type="number"
              placeholder="Montant en F CFA"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!amount}
              onClick={onValidateAmount}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Effectuer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Pin */}
      {displayPasswordModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end md:justify-center"
          onClick={($event) => {
            setDisplayAmountModal(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto max-w-md p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            {" "}
            <h2 className="text-lg uppercase font-bold">Mot de passe</h2>
            <h5 className="text-sm">
              {" "}
              Voulez-vous generer cette transaction de{" "}
              <span className="text-tertiary">{amount}</span>?
            </h5>
            <input
              onChange={($event) => setPassword($event.target.value)}
              type="password"
              placeholder="Votre mot de passe"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!password}
              onClick={onMakeOperation}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Confimer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {displayDetails ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end md:justify-center"
          onClick={($event) => {
            setDisplayDetails(false);
          }}
        >
          {operations.length > 0 ? (
            <div
              onClick={($event) => {
                $event.stopPropagation();
              }}
              className="mx-auto max-w-md p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
            >
              {" "}
              <h2 className="text-md uppercase font-bold">
                {operations[0].id}
              </h2>
              <h5 className="text-lg">
                {" "}
                Initié par
                <span className="text-tertiary">
                  {" "}
                  {operations[0].sender_name}{" "}
                </span>
              </h5>
              {hasVoted() ? (
                <></>
              ) : (
                <div className="flex justify-between gap-4">
                  <button
                    onClick={rejectTrans}
                    className="bg-red-500 px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
                  >
                    Rejeter
                  </button>

                  <button
                    onClick={approveTrans}
                    className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
                  >
                    Approuver
                  </button>
                </div>
              )}
              <h5 className="text-lg">Liste des votants</h5>
              <div className="w-full  space-y-4 h-96">
                {operations[0].approvals.map((approval: any) => (
                  <div
                    key={approval.id}
                    className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2"
                  >
                    <div className="p-2 flex gap-2 items-center">
                      <UserIcon className="w-8 h-8 mx-auto text-primary" />
                      <div>
                        <h3 className="text-xs text-primary">
                          {approval.approver_name}
                        </h3>
                        <p className="text-sm text-gray-400">
                          {approval.status ? "OUI" : "NON"}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-xs text-gray-500">
                        {approval.date} à {approval.time}
                      </p>
                      {/* <span className="text-sm text-primary">
                    {+support.cash_balance} F CFA
                  </span> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      {loader ? (
        <div
          className="absolute  top-0 left-0 right-0 bottom-0 w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-center"
          onClick={($event) => {
            setLoader(false);
          }}
        >
          <div className="mx-auto max-w-md p-4 rounded-lg bg-transparent bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}

            <img src={LoaderImg} alt="" />
            <p className="text-gray-500 italic text-sm">Connexion...</p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Response */}
      {displayResModal ? (
        <div
          className="absolute  top-0 left-0 right-0 bottom-0 w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end md:justify-center"
          onClick={($event) => {
            setDisplayResModal(false);
          }}
        >
          <div className="mx-auto max-w-md p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}
            {response?.success ? (
              <CheckCircleIcon className="w-32 h-32 text-secondary" />
            ) : (
              <>
                {" "}
                <InformationCircleIcon className="w-32 h-32 text-red-500" />
                <p className="text-red-500 italic text-sm">
                  {response?.message}
                </p>
              </>
            )}
            <button
              onClick={() => setDisplayResModal(false)}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Fermer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="w-full h-full overflow-hidden">
        <div className="h-16 w-full  bg-white p-1 flex justify-center items-center">
          <h3 className="text-lg">Compte Direction</h3>
          {/* {displayMenu ? (
            <button
              onClick={() => setDisplayMenu(false)}
              className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square"
            >
              <ArrowLeftIcon className="w-8" />
            </button>
          ) : (
            <button
              onClick={() => setDisplayMenu(true)}
              className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square"
            >
              <Bars3Icon className="w-8" />
            </button>
          )} */}
        </div>
        <div className="space-x-4 flex items-center p-2 ">
          {" "}
          {/* <h3 className="text-primary font-bold text-lg">
            Salut {profile?.pseudo} !
          </h3> */}
        </div>
        <div className="w-full p-8">
          <div
            className={`max-w-md mx-auto bg-secondary text-white w-full h-36 flex flex-col justify-between shadow-lg rounded-xl  font-serif p-4`}
          >
            <div className="w-full flex justify-center text-xl">
              <h3 className="uppercase">{profile?.name}</h3>
            </div>
            <div>
              <h4 className="text-center font-mono text-gray-200">
                {profile?.title}
              </h4>
            </div>
          </div>
        </div>
        {/* Operations */}

        {/* Historique */}
        <div className="space-y-4 p-8 ">
          <div className="flex justify-between">
            <h2 className="text-primary text-lg">Opèration en attente</h2>
            {profile?.title === "ceo" && !operations.length ? (
              <button
                className="bg-primary text-white rounded-l-full rounded-r-full px-2 py-1"
                onClick={() => setDisplayAmountModal(true)}
              >
                Créer une transaction
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="w-full  space-y-4 h-96">
            {operations.map((operation) => (
              <div
                key={operation.id}
                onClick={() => setDisplayDetails(true)}
                className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2"
              >
                <div className="p-2 flex gap-2 items-center">
                  <ArrowPathIcon className="w-8 h-8 mx-auto text-primary" />
                  <div>
                    <h3 className="text-xs text-primary">
                      {" "}
                      Transaction {operation.id}
                    </h3>
                    <p className="text-sm text-black">
                      Votes: {operation.approvals.length}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-xs text-gray-500">
                    {operation.date} à {operation.time}
                  </p>
                  <span className="text-sm text-primary">
                    {+operation.amount} F CFA
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

import {
  ArrowLeftIcon,
  Cog6ToothIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import LogoImg from "../../assets/img/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSupport } from "../../store/support";

const SupportDiscussion = () => {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState<any[]>([]);
  const [subject, setSubject] = useState<any>();
  const [messages, setMessages] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const { sendMessage, getMessages, getDiscussions, getUnreadMessages } =
    useSupport();
  const params = useParams();
  let interval: any;

  useEffect(() => {
    const subjectId = params["id"];
    if (!subjectId) return;
    loadSubjects(subjectId);
    return interval;
  }, []);
  //   window.setInterval(() => loadMessages(), 10 * 1000);

  const onSend = async () => {
    await sendMessage(subject.id, message);
    setMessage("");
    loadMessages(subject.id);
  };

  const loadSubjects = async (id: string) => {
    console.log(id);
    const data = await getDiscussions();
    setSubjects(data);
    const item = data.find((el) => el.id === id);
    setSubject(item);
    loadMessages(id);
    interval = setInterval(() => {
      loadUnReadMessage(id);
    }, 10 * 1000);
    //   return () => clearInterval(interval);
  };

  const loadMessages = async (id: string) => {
    const data = await getMessages(id);
    setMessages(data);
  };

  const loadUnReadMessage = async (id: string) => {
    if (!subject) return;
    const data = await getUnreadMessages(subject.id);
    const newMessages = [...data, ...messages];
    setMessages(newMessages);
  };
  const goBack = () => {
    navigate("/dashboard/support");
  };

  return (
    <div className="w-full h-full  bg-white p-8">
      <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
        <button onClick={goBack} className="">
          <ArrowLeftIcon className="w-8" />
        </button>
        <h3>Sujet: {subject?.subject} </h3>
        <button className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square">
          <Cog6ToothIcon className="w-8" />
        </button>
      </div>

      <div className="h-full w-full space-y-4 relative">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`w-full  shadow-md p-2 flex items-center justify-between  space-y-4 rounded-xl gap-2 ${
              message.type == "sender" ? "bg-white" : "bg-green-50"
            }`}
          >
            <div className="p-2 flex gap-2 items-center">
              {/* <ChatBubbleBottomCenterIcon className="w-8 h-8 mx-auto text-primary" /> */}
              <div>
                <h3 className="text-xs text-primary">
                  {message.type === "sender" ? "Moi" : "NamDje"}
                </h3>
                <p className="text-sm text-gray-400">{message.message}</p>
              </div>
            </div>
            <div>
              <p className="text-xs text-gray-500">
                {message.date} à {message.time}
              </p>
              <span className="text-sm text-primary">{message.status}</span>
            </div>
          </div>
        ))}

        <div className="mx-auto py-8 absolute -bottom-12 z-40 bg-white bg-opacity-100 w-full flex gap-2 items-center">
          <textarea
            onChange={($event) => setMessage($event.target.value)}
            rows={1}
            value={message}
            className="w-full  text-black outline-none rounded-lg p-4 text-md  border-2 border-primary"
            placeholder="Entrer votre message ici..."
          ></textarea>

          <button
            disabled={!subject || !message}
            onClick={onSend}
            className="bg-secondary  p-4 h-fit rounded-l-full rounded-r-full text-dark"
          >
            <PaperAirplaneIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SupportDiscussion;

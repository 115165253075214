import React from "react";

const Modal = ({ children, handleClick }: any) => {
  return (
    <div
      className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
      onClick={handleClick}
    >
      <div
        onClick={($event) => {
          $event.stopPropagation();
        }}
        className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../store";
import { AccountType } from "../../models";
import LoaderImg from "../../assets/img/loader.gif";
import {
  UserIcon,
  BanknotesIcon,
  BuildingOffice2Icon,
  ArrowLeftIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../configs/firebase";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const VerifyPasswordPage = () => {
  const navigate = useNavigate();

  const [userId, setUserId] = useState<string>();
  const [password, setPassword] = useState<string>();
  const { login, verifyPhoneNumber } = useAuth();
  const [loader, setLoader] = useState(false);
  const [displayResModal, setDisplayResModal] = useState(false);
  const [response, setResponse] = useState<any>();
  const [verifier, setVerifier] = useState<any>();

  useEffect(() => {
    let verify = new RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" },
      auth
    );
    setVerifier(verify);
  }, []);

  const goBack = () => {
    navigate("/");
  };

  //password validation handler
  const submitHandler = async () => {
    if (!password) return;
    setLoader(true);
    let res: any;
    res = await login({
      phone: userId,
      account_type: AccountType.DIRECTION,
      password,
    });

    setResponse({
      success: res?.isAuth,
      message: res?.message,
      remainingTrials: res?.remaining_trials,
    });
    setDisplayResModal(true);
    if (res?.isAuth) {
      navigate("/dashboard/home");
    }
    setLoader(false);
  };

  const onRecover = async () => {
    if (!userId) return;
    const res = await verifyPhoneNumber(userId, verifier);
    if (!res) return;
    sessionStorage.setItem("nj_recovering", "yes");
    return navigate("/otp");
  };

  return (
    <>
      {loader ? (
        <div
          className="absolute  top-0 left-0 right-0 bottom-0 w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-center"
          onClick={($event) => {
            setLoader(false);
          }}
        >
          <div className="mx-auto max-w-md p-4 rounded-lg bg-transparent bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}

            <img src={LoaderImg} alt="" />
            <p className="text-gray-500 italic text-sm">Connexion...</p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Response */}
      {displayResModal ? (
        <div
          className="absolute  top-0 left-0 right-0 bottom-0 w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end md:justify-center"
          onClick={($event) => {
            setDisplayResModal(false);
          }}
        >
          <div className="mx-auto max-w-md p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}
            {response?.success ? (
              <CheckCircleIcon className="w-32 h-32 text-secondary" />
            ) : (
              <>
                {" "}
                <InformationCircleIcon className="w-32 h-32 text-red-500" />
                <p className="text-red-500 italic text-sm">
                  {response?.message}
                </p>
              </>
            )}
            {response?.success ? (
              <div className="flex flex-col gap-4">
                <button
                  onClick={() => {
                    setPassword("");
                    setDisplayResModal(false);
                  }}
                  className="bg-cyan-500  py-2 rounded-l-full rounded-r-full h-fit text-dark"
                >
                  Resssayer ({response?.remainingTrials} tentatives restantes)
                </button>
                {/* <button
                  onClick={onRecover}
                  className="bg-tertiary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
                >
                  Recupérer mon mot passe
                </button> */}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="h-full flex flex-col items-center justify-between pt-12 max-w-md mx-auto">
        <div className="space-y-8">
          <h1 className="text-blue-200">Compte Direction</h1>
          <UserIcon className="text-blue-200 animate-bounce" />
        </div>

        {/* <div className="w-full flex justify-center text-white text-xl gap-2">
          <button className="" onClick={goBack}>
            {" "}
            <ArrowLeftIcon className="text-white w-8" />{" "}
          </button>{" "}
        </div> */}
        {/* <h2 className="text-purple-100 text-3xl "></h2> */}
        <div className="w-[calc(100%+4rem)] h-80 flex flex-col justify-around items-center  rounded-2xl bg-light  -mb-8 p-4 border-b-black">
          <h3 className="text-primary italic">Veuillez vous connecter</h3>
          <PhoneInput
            className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary p-6 rounded h-10 text-primary text-xl"
            placeholder="Enter phone number"
            value={userId}
            onChange={setUserId}
          />
          <input
            className="w-full text-left  text-black outline-none rounded-l-full rounded-r-full px-8 py-2 text-md  border-2 border-primary"
            onChange={($event: any) => setPassword($event.target.value)}
            type="password"
            placeholder="Votre mot de passe"
          />
          <div>
            <button
              className="bg-secondary px-8 py-2 w-full rounded-l-full rounded-r-full border text-dark"
              onClick={submitHandler}
            >
              Confirmez
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyPasswordPage;

import {
  ArrowLeftOnRectangleIcon,
  BellIcon,
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
  DocumentCheckIcon,
  HomeIcon,
  QrCodeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useAuth, useNotification } from "../store";
import { AccountType, IProfile } from "../models";
import LogoImg from "../assets/img/logo.png";

const AuthLayout = () => {
  const { getProfile, logout } = useAuth();
  const navigate = useNavigate();

  const [profile, setProfile] = useState<IProfile>();
  const { getFcmToken } = useNotification();
  const [accountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );
  useEffect(() => {
    const localProfile = getProfile();
    getFcmToken();
    // console.log(profile);
    localProfile ? setProfile(localProfile) : navigate("/");
  }, []);

  const onLogout = async () => {
    const res = await logout(accountType);
    if (res) navigate("/");
  };

  return (
    <div className="h-screen w-full">
      <div className="h-[calc(100%-6em)]  md:h-full flex  bg-gray-50 w-full">
        {/* layout section */}
        <div className="w-64 hidden md:flex flex-col justify-between h-full bg-primary p-8">
          <div className="flex justify-center">
            <img src={LogoImg} alt="" />
          </div>

          <div className="h-full space-y-4 py-16">
            <NavLink
              to="/dashboard/home"
              className="w-full flex justify-between items-center gap-4 space-y-4 text-white"
            >
              <div className="flex gap-4 items-center ">
                <HomeIcon className="w-4 h-4 " />
                <h3>Accueil</h3>
              </div>
              {/* <ChevronRightIcon className="w-4 h-4" /> */}
            </NavLink>

            <NavLink
              to="/dashboard/profile"
              className="w-full flex justify-between items-center gap-4 space-y-4 text-white"
            >
              <div className="flex gap-4 items-center ">
                <UserIcon className="w-4 h-4 " />
                <h3>Profile</h3>
              </div>
              {/* <ChevronRightIcon className="w-4 h-4" /> */}
            </NavLink>

            <NavLink
              to="/dashboard/historique"
              className="w-full flex justify-between items-center gap-4 space-y-4 text-white"
            >
              <div className="flex gap-4 items-center ">
                <DocumentCheckIcon className="w-4 h-4 " />
                <h3>Historique</h3>
              </div>
              {/* <ChevronRightIcon className="w-4 h-4" /> */}
            </NavLink>

            <NavLink
              to="/dashboard/transactions"
              className="w-full flex justify-between items-center gap-4 space-y-4 text-white"
            >
              <div className="flex gap-4 items-center ">
                <DocumentCheckIcon className="w-4 h-4 " />
                <h3>Transactions</h3>
              </div>
              {/* <ChevronRightIcon className="w-4 h-4" /> */}
            </NavLink>

            <NavLink
              to="/dashboard/notifications"
              className="w-full flex justify-between items-center gap-4 space-y-4 text-white"
            >
              <div className="flex gap-4 items-center ">
                <BellIcon className="w-4 h-4 " />
                <h3>Notifications</h3>
              </div>
              {/* <ChevronRightIcon className="w-4 h-4" /> */}
            </NavLink>
          </div>

          <div>
            <div
              onClick={onLogout}
              className="w-full flex justify-between items-center gap-4 space-y-4 text-white"
            >
              <div className="flex gap-4 items-center ">
                <ArrowLeftOnRectangleIcon className="w-4 h-4 " />
                <h3> Se déconnecter</h3>
              </div>
              {/* <ChevronRightIcon className="w-4 h-4" /> */}
            </div>
          </div>
        </div>

        <Outlet />
      </div>
      <div className="md:hidden bg-light h-24 w-full   shadow-xl rounded-t-2xl  text-gray-600 grid grid-flow-col auto-cols-fr  p-4">
        <NavLink
          to="/dashboard/home"
          className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
        >
          <HomeIcon className="w-[1.5rem]" />
          <h3 className="text-xs leading-none">Accueil</h3>
        </NavLink>
        <NavLink
          to="/dashboard/historique"
          className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
        >
          <DocumentCheckIcon className="w-[1.5rem]" />
          <h3 className="text-xs leading-none">Historique</h3>
        </NavLink>
        <NavLink
          to="/dashboard/transactions"
          className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
        >
          <DocumentCheckIcon className="w-[1.5rem]" />
          <h3 className="text-xs leading-none">Transactions</h3>
        </NavLink>
        <NavLink
          to="/dashboard/notifications"
          className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
        >
          <BellIcon className="w-[1.5rem]" />
          <h3 className="text-xs leading-none">Notifications</h3>
        </NavLink>
        <NavLink
          to="/dashboard/profile"
          className="h-full flex flex-col items-center justify-around text-gray-600 active:text-primary"
        >
          <UserIcon className="w-[1.5rem]" />
          <h3 className="text-xs leading-none">Profil</h3>
        </NavLink>
      </div>
    </div>
  );
};

export default AuthLayout;

import axios, { AxiosHeaders } from "axios";
import { useNavigate } from "react-router-dom";
// import { getCookie, setCookie } from 'typescript-cookie'

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

export const useApi = () => {
  const navigate = useNavigate();
  const api = axios.create({
    // baseURL: process.env.REACT_APP_LOCAL_URL2 || 'http://127.0.0.1:8000/',
    baseURL: '/',
  });

  api.interceptors.response.use(
    (response: any) => response, // Pass through successful responses
    async (error: any) => {
      if (error.response?.status === 401) {
        navigate("/");
      }
      return Promise.reject(error);
    }
  );

  const getHeaders = (privateAccess: boolean, secured?: boolean) => {
    const headers: AxiosHeaders = new AxiosHeaders();
    headers.setContentType("multipart/form-data");
    return headers;
  };

  const makeFormData = (obj: any) => {
    const formData = new FormData();
    Object.keys(obj).forEach((key) => formData.append(key, obj[key]));
    return formData;
  };

  const setTokens = (token: string, refershToken: string) => {
    sessionStorage.setItem("nj_token", token);
    sessionStorage.setItem("nj_refreshToken", refershToken);
  };

  const $get = async (path: string) => {
    try {
      const { data } = await api.get(path, {
        headers: getHeaders(false),
        withCredentials: true,
      });
      return data;
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const $post = async (
    path: string,
    body: any,
    privateAccess = true,
    secured = false
  ) => {
    const formData = makeFormData(body);
    try {
      const { data } = await api.post(path, formData);

      return data;
    } catch (err: any) {
      if (err.status) console.log(err);
      console.log(err?.response);
      // window.alert(err?.response?.data.detail)
    }
  };

  const $put = async (path: string, body: any) => {
    try {
      const { data } = await api.put(path, body, {
        headers: getHeaders(true),
      });
      return data;
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const $patch = async (path: string, body: any) => {
    try {
      const { data } = await api.patch(path, body, {
        headers: getHeaders(true),
      });
      return data;
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const $delete = async (path: string) => {
    try {
      const { data } = await api.delete(path, {
        headers: getHeaders(true),
      });
      return data;
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const objToArray = (data: any): any[] => {
    // console.log(typeof data);
    if (typeof data !== "object") return data;
    const formatedData: any[] = [];
    Object.keys(data).map((item, i) => {
      const operation = Object.keys(data[item]).map((key, i) => ({
        id: key,
        date: item,
        ...data[item][key],
      }));
      formatedData.push(...operation);
    });
    return formatedData;
  };

  return {
    getHeaders,
    setTokens,
    $post,
    $get,
    $put,
    $patch,
    $delete,
    objToArray,
  };
};

import { getToken } from "firebase/messaging"
import { messaging } from "../configs/firebase"
import { useApi } from "./api"

export const useNotification = () => {
    const { $post, $get, objToArray } = useApi()

    const getFcmToken = async () => {
        const token = await getToken(messaging, { vapidKey: "BOuNhdd-10rqvHU7bbYWnCbQPDolQknyfEGm7ml9Sm2DkOyAbuqJN5r81i1NwoN_h2moTb9_O-X43iRE4Uts7xs" })
        // console.log('token', token)
        const res = await $post('backend/account/device/', { token }, false)
        // return res;
    }

    const getNotifications = async () => {
        const { data } = await $get('backend/account/notification/')
        if (data) {
            const result = objToArray(data)
            return result
        }
        return [];
    }

    const setNotificationAsRead = async (notification_id: string) => {
        return await $post('backend/mark_notification_read/', { notification_id }, false)
    }

    return {
        getFcmToken,
        getNotifications,
        setNotificationAsRead
    }
}
import { AccountType } from "../models"
import { useApi } from "./api"


export const useSupport = () => {
    const { $post, $get, objToArray } = useApi()

    const getSupports = async () => {
        const { data } = await $post('get_sub_accounts/', { account_type: AccountType.SUPPORT }, false)
        if (data) {
            console.log(data);
            const result = objToArray(data)
            return result
        }
        return [];
    }

    const addSupport = async (data: any) => {
        try {
            const res = await $post('signup_sub_account/', data, false)
            console.log(res);
            return
        } catch (err) {
            window.alert("Echec d'enregistrement du commercial!")
        }
    }

    const createDiscussion = async (subject: string, message: string) => {
        try {
            const res = await $post('create_support_message_for_support_account/', { subject, message }, false)
            console.log(res);
            return
        } catch (err) {
            window.alert("Echec d'enregistrement du contact!")
        }
    }

    const getDiscussions = async () => {
        const { data } = await $get('get_support_message_subjects_for_support_account/')
        if (data) {
            const result = objToArray(data);
            console.log(result);
            return result;
        }
        return [];
    }

    const sendMessage = async (subject_id: string, message: string) => {
        try {
            const res = await $post('create_support_message/', { subject_id, message }, false)
            console.log(res);
            return
        } catch (err) {
            window.alert("Echec d'enregistrement du contact!")
        }
    }

    const getMessages = async (subject_id: string) => {
        const { data } = await $post('get_support_messages_for_support_account/', { subject_id }, false)
        if (data) {
            const result = objToArray(data);
            console.log(result);
            return result;
        }
        return [];
    }

    const getUnreadMessages = async (subject_id: string) => {
        const { data } = await $post('get_unread_support_messages_for_support_account/', { subject_id }, false)
        if (data) {
            const result = objToArray(data);
            console.log(result);
            return result;
        }
        return [];
    }
    

    return { addSupport, getSupports, createDiscussion, getDiscussions, getUnreadMessages, sendMessage, getMessages }

}

import { useApi } from "./api";

export const useTransaction = () => {
    const { $post, $get, objToArray } = useApi()
    const createTransaction = async (amount: number, password: string) => {
        const res = await $post('direction/transaction/', { amount, password }, false)
        console.log(res);
        return res
    }

    const approveTransaction = async (transaction_id: string) => {
        const res = await $post('direction/transaction/approve/', { transaction_id }, false)
        console.log(res);
        return res
    }

    const rejectTransaction = async (transaction_id: string) => {
        const res = await $post('direction/transaction/reject/', { transaction_id }, false)
        console.log(res);
        return res
    }

    const getTransactions = async (req: {
        start_date?: string,
        end_date?: string,
        year?: string,
        month?: string,
        day?: string
    }) => {
        const data: any = {};
        const keys: any = req;
        const params = Object.keys(keys).filter((key) => { if (keys[key]) return key })
        params.forEach(param => data[param] = keys[param])
        console.log("data", data);
        const res = await $get('direction/transaction/')
        if (res) return res.data
    }

    const getPendingTransactions = async () => {
        const res = await $get('direction/transaction/get_pending_transactions/')
        console.log(res)
        // const dataArr = objToArray(res.data).map(item => ({ ...item, approvals: objToArray(item.approvals) }))
        console.log(res)
        return res?.data || []
    }

    const getSystemTransactions = async (req: {
        start_date?: string,
        end_date?: string,
        year?: string,
        month?: string,
        day?: string
    }) => {
        const data: any = {};
        const keys: any = req;
        const params = Object.keys(keys).filter((key) => { if (keys[key]) return key })
        params.forEach(param => data[param] = keys[param])
        console.log("data", data);
        const res = await $get('direction/transaction/get_normal_transactions/')
        if (res) return res.data
    }


    return { createTransaction, approveTransaction, getTransactions, getPendingTransactions, rejectTransaction, getSystemTransactions }
}

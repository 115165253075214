import { useApi } from "./api"

export const useDocument = () => {
    const { $post, $get, objToArray } = useApi()

    const getDocumentsAccounts = async () => {
        const { data } = await $get('get_account_documents_for_support_account/')
        if (data) {
            const result = objToArray(data);
            console.log(result);
            return result;
        }
        return [];
    }

    const getDocument = async (account_document_id: string) => {
        const { data } =  await $post('get_documents_for_support_account/', {account_document_id}, false)
        console.log(data)
        if (data) {
            const result = objToArray(data);
            console.log(result);
            return result;
        }
    }
    const validateDocument = async (credentials: any) => {
        
        const res =  await $post('verify_documents_for_support_account/', credentials, false)
        return res
    }

    return { getDocumentsAccounts, getDocument, validateDocument }
}
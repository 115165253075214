import {
  ArrowLeftIcon,
  ChatBubbleBottomCenterIcon,
  Cog6ToothIcon,
  DocumentCheckIcon,
} from "@heroicons/react/24/outline";
import LogoImg from "../../assets/img/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDocument } from "../../store";

const Document = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<any[]>([]);
  const [displayForm, setDisplayForm] = useState(false);
  const [document, setDocument] = useState<any>();
  const [comment, setComment] = useState("");
  const [expirationDate, setExpirationDate] = useState<any>();
  const [validity, setValidity] = useState<any>();
  const { getDocument, validateDocument } = useDocument();
  const [documentId, setDocumentId] = useState<string>("");
  const params = useParams();

  useEffect(() => {
    loadDocument();
  }, []);

  const loadDocument = async () => {
    const documentId = params["id"];
    if(!documentId) return
    setDocumentId(documentId)
    const data = (await getDocument(documentId)) || [];
    setDocuments(data);
  };

  const onSend = async () => {
    const res = await validateDocument({
      document_id: documentId,
      document_validity: validity,
      expiration_date: expirationDate,
      comment,
    });
    goBack();
  };

  const goBack = () => {
    navigate("/dashboard/documents");
  };

  const goToDiscussion = (id: string) => {
    navigate("/dashboard/document/" + id);
  };
  

  return (
    <>
      {/* Details */}
      {displayForm ? (
        <div className="absolute w-full h-screen bg-black bg-opacity-70  z-40 flex flex-col justify-end">
          <div className="mx-auto bg-white bg-opacity-100 w-full rounded-t-2xl py-16 z-50 px-4 space-y-8">
            <h2 className="text-center text-lg font-bold">
              Validation du document
            </h2>

            <select 
            onChange={($event) => setValidity($event.target.value)}
            placeholder="Document valide">
              <option value="true" >Valide</option>
              <option value="false" >Non Valide</option>
            </select>

            <input
              onChange={($event) => setExpirationDate($event.target.value)}
              type="date"
              placeholder="Date d'expiration"
              className="w-full p-4  text-black outline-none rounded-l-full rounded-r-full  text-md  border-2 border-primary"
            />

            <textarea
              onChange={($event) => setComment($event.target.value)}
              className="w-full  text-black outline-none rounded-l-full rounded-r-full p-4 text-md  border-2 border-primary"
              placeholder="Ajouter un commentaire ici..."
            ></textarea>

            <button
              onClick={onSend}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full text-dark w-full"
            >
              Envoyer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="w-full h-full  bg-white p-8">
        <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
          {/* <button onClick={goBack} className="">
            <ArrowLeftIcon className="w-8" />
          </button> */}
          <button></button>
          <h3>Documents</h3>
          <button className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square">
            <Cog6ToothIcon className="w-8" />
          </button>
        </div>

        <div className="w-full my-8">
          {/* <button
            onClick={() => setDisplayForm(true)}
            className="bg-tertiary w-full py-1 rounded-lg text-white"
          >
            Comptes á Valider
          </button> */}

          {documents.map((document) => (
            <div
              key={document.id}
              onClick={() => goToDiscussion(document.id)}
              className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2"
            >
              <div className="p-2 flex gap-2 items-center">
                <DocumentCheckIcon className="w-8 h-8 mx-auto text-primary" />
                <div>
                  <h3 className="text-xs text-primary">{document.document}</h3>
                  <p className="text-sm text-gray-400">
                     {document.status}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-xs text-gray-500">
                  {document.date} à {document.time}
                </p>
                {/* <span className="text-sm text-primary">
                  {+subject.unread} non lu
                </span> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Document;

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./configs/firebase";
import AuthLayout from "./layouts/AuthLayout";
import Otp from "./pages/auth/Otp";
import Register from "./pages/auth/Register";
import VerifyPasswordPage from "./pages/auth/VerifyPasswordPage";
import ConfirmEmail from "./pages/auth/ConfirmEmail";
import PrivateLayout from "./layouts/PrivateLayout";
import Home from "./pages/private/Home";
import Historique from "./pages/private/Historique";
import Notifications from "./pages/private/Notifications";
import Profile from "./pages/private/Profile";
import Settings from "./pages/private/Setting";
import Cug from "./pages/private/Cug";
import Support from "./pages/private/Support";
import SupportDiscussion from "./pages/private/SupportDiscussion";
import Information from "./pages/private/Information";
import Nations from "./pages/private/Nations";
import Supports from "./pages/private/Members";
import Documents from "./pages/private/Documents";
import Document from "./pages/private/Document";
import Transactions from "./pages/private/Transactions";
// import Historique from "./pages/private/Historique";
// import Notifications from "./pages/private/Notifications";
// import Profile from "./pages/private/Profile";
// import Scan from "./pages/private/Scan";
// import ScanResult from "./pages/private/ScanResult";
// import ComptesBancaires from "./pages/private/CompteBancaires";
// import Loader from "./components/utils/Loader";
// import Card from "./pages/private/Card";
// import Information from "./pages/private/Information";
// import Settings from "./pages/private/Setting";
// import Cug from "./pages/private/Cug";
// import Support from "./pages/private/Support";
// import SupportDiscussion from "./pages/private/SupportDiscussion";

function App() {
  return (
    <main className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            {/* <Route path="" element={<LoginPage />}></Route> */}
            <Route path="otp" element={<Otp />}></Route>
            <Route path="register" element={<Register />}></Route>
            <Route path="" element={<VerifyPasswordPage />}></Route>
            <Route path="confirmmail" element={<ConfirmEmail />}></Route>
          </Route>
          <Route path="dashboard" element={<PrivateLayout />}>
            <Route path="home" element={<Home />}></Route>
            <Route path="nations" element={<Nations />}></Route>
            <Route path="supports" element={<Supports />}></Route>
            <Route path="historique" element={<Historique />}></Route>
            <Route path="transactions" element={<Transactions />}></Route>
            <Route path="notifications" element={<Notifications />}></Route>
            <Route path="profile" element={<Profile />}></Route>
            <Route path="infos" element={<Information />}></Route>
            <Route path="settings" element={<Settings />}></Route>
            <Route path="cug" element={<Cug />}></Route>
            <Route path="support" element={<Support />}></Route>
            <Route path="support/:id" element={<SupportDiscussion />}></Route>
            <Route path="documents" element={<Documents />}></Route>
            <Route path="document/:id" element={<Document />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;

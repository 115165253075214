import React, { useEffect, useState } from "react";
import LogoImg from "../../assets/img/logo.png";
import { BellAlertIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useNotification } from "../../store";

const Notifications = () => {
  const { getNotifications, setNotificationAsRead } = useNotification();
  const [notifications, setNotifications] = useState<any[]>([]);
  const [displayNotif, setDisplayNotif] = useState<boolean>(false);
  const [selectedNotif, setSelectedNotif] = useState<any>();

  useEffect(() => {
    loadNotifications();
  }, []);

  const loadNotifications = async () => {
    const response = await getNotifications();
    console.log("notifications", response.reverse());
    setNotifications(response);
  };

  const markAsread = async (id: string) => {
    const notif = notifications.find((notif) => notif.id === id);
    setSelectedNotif(notif);
    setDisplayNotif(true);
    console.log(notif);
    if (notif.status === "unread") {
      const res = await setNotificationAsRead(id);
      console.log(res);
      await loadNotifications();
    }
  };

  return (
    <div className="h-full w-full overflow-hidden">
      {displayNotif ? (
        <div
          className="absolute w-full h-screen bg-black bg-opacity-70  z-40 flex flex-col justify-end"
          onClick={() => setDisplayNotif(false)}
        >
          <div className="mx-auto bg-white bg-opacity-100 w-full rounded-t-2xl py-16 z-50">
            <h2>{selectedNotif.subject}</h2>
            <h2>
              {selectedNotif.date} à {selectedNotif.time}
            </h2>
            <p> {selectedNotif.message}</p>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="h-16 w-full bg-white  p-1 flex justify-center items-center">
        <h3 className="text-lg">Notifications</h3>
      </div>

      <div className="space-y-4 h-[calc(100%-4rem)] overflow-auto p-8">
        <h2 className="text-primary text-lg">Mes notifications</h2>
        <div className="w-full  space-y-4">
          {notifications.map((notification) => (
            <div
              onClick={() => markAsread(notification.id)}
              key={notification.id}
              className={`w-full ${
                notification.status === "unread" ? "bg-white " : "bg-gray-100"
              } shadow-sm p-2 flex items-center justify-between rounded-xl gap-2`}
            >
              <div className="p-2 flex gap-2 items-center">
                <BellAlertIcon className="w-8 h-8 mx-auto text-tertiary" />
                <div>
                  <h3 className="text-xs text-primary">
                    {notification.subject}
                  </h3>
                  <p className="text-[8px] text-gray-400">
                    {notification.message}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-xs text-gray-500">
                  {notification.date} à {notification.time}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Notifications;

import { useState } from "react"

export const useShared = () => {
const [loader, setLoader] = useState({ display: true, message: "Chargemenet...", timeout: 2 });
const [alert, setAlert ] = useState({ display: false, title: "Alert",  message: "Ceci est un alert",  buttons: [{ title:"Continuer", action: () => console.log("clicked!")}] })

const displayLoader = (message: string, timeout: number) => {
    setLoader({ display: true, timeout, message})
}

const dismissLoader = () => {
    setLoader({ display: false, timeout: 15, message: "Chargement..."})
}

const displayAlert = (data: {
    title: string;
    message: string;
    buttons: { title: string; action: () => void  }[]
}) => {
    setAlert({display: true, ...data})
}

const dismissAlert = () => {
    setAlert({display: false, title: "", message: '', buttons: []})
}



return { loader,displayLoader, dismissLoader,  alert, displayAlert, dismissAlert}
}
import { ArrowLeftIcon, ChatBubbleBottomCenterIcon, Cog6ToothIcon, DocumentCheckIcon } from "@heroicons/react/24/outline";
import LogoImg from "../../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDocument } from "../../store";

const Documents = () => {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState<any[]>([]);
  const [displayForm, setDisplayForm] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { getDocumentsAccounts, getDocument } = useDocument();

  useEffect(() => {
    loadDocuments();
  }, []);

  const loadDocuments = async () => {
    const data = await getDocumentsAccounts();
    setSubjects(data.reverse());
  };
  

  // const onNext = async () => {
  //   await loadDocuments();
  //   const newSubject = subjects[0] 
  //   navigate('/dashboard/support/' + newSubject.id)
  // };

  // const goBack = () => {
  //   navigate("/dashboard/profile");
  // };

  const goToDiscussion = (id: string) => {
    navigate("/dashboard/document/" + id)
  }

  return (
    <>
    
      <div className="w-full h-full  bg-white p-8">
        <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
          {/* <button onClick={goBack} className="">
            <ArrowLeftIcon className="w-8" />
          </button> */}
          <button></button>
          <h3>Comptes</h3>
          <button className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square">
            <Cog6ToothIcon className="w-8" />
          </button>
        </div>

        <div className="w-full my-8">
          {/* <button
            onClick={() => setDisplayForm(true)}
            className="bg-tertiary w-full py-1 rounded-lg text-white"
          >
           Comptes á Valider
          </button> */}

          {subjects.map((subject) => (
            <div
              key={subject.id}
              onClick={() => goToDiscussion(subject.id)}
              className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2"
            >
              <div className="p-2 flex gap-2 items-center">
                <DocumentCheckIcon className="w-8 h-8 mx-auto text-primary" />
                <div>
                  <h3 className="text-xs text-primary">{subject.subject}</h3>
                  <p className="text-sm text-gray-400">
                     {subject.status}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-xs text-gray-500">
                  {subject.date} à {subject.time}
                </p>
                {/* <span className="text-sm text-primary">
                  {+subject.unread} non lu
                </span> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Documents;
